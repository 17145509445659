import ChatWidget from '../../../../components/ChatWidget';
import SideBar from '../../../../components/SideBar';
import { USHG_HQ_APP_ROLE } from '../../../../constants';
import { useAppSelector } from '../../../../hooks';
import { RootState } from '../../../../store';
import HasAccess from '../HasAccess';
import {
  DashboardNavItem,
  CoursesNavItem,
  TeamsNavItem,
  ReportsNavItem,
  MessagesNavItem,
  NotificationsNavItem,
  SettingsNavItem,
} from './components';
import BadgesNavItem from './components/BadgesNavItem';
import IndividualNavItem from './components/IndividualNavItem';
import LearningJourneysNavItem from './components/LearningJourneysNavItem';
import MyBadgesNavItem from './components/MyBadgesNavItem';

import MyTeamsNavItem from './components/MyTeamsNavItem';
import OrganisationNavItem from './components/OrganisationNavItem';
import RequestsNavItem from './components/RequestsNavItem';
import SpeakersNavItem from './components/SpeakersNavItem';
import SubscriptionReminder from './components/SubscriptionReminder';

interface Props {
  className?: string;
  defaultCollapsed?: boolean;
}

function AppSideBar(props: Props) {
  // check the condition for displaying the chatbot from strapi content
  const strapiDataSelector = (state: RootState) => state.strapi.strapiData;
  const data = useAppSelector(strapiDataSelector);
  const showChat = data.hqFront.data.attributes.Chat.showChat;

  return (
    <SideBar
      top={
        <>
          <DashboardNavItem />
          <HasAccess
            roles={[
              USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
              USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
              USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
              USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
              USHG_HQ_APP_ROLE.INDIVIDUAL_USER,
              USHG_HQ_APP_ROLE.USHG_ADMIN,
            ]}
            Fallback={null}
          >
            <CoursesNavItem />
          </HasAccess>
          <HasAccess
            roles={[
              USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
              USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
              USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
              USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
              USHG_HQ_APP_ROLE.INDIVIDUAL_USER,
            ]}
            Fallback={null}
          >
            <LearningJourneysNavItem />
          </HasAccess>
          <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={null}>
            <SpeakersNavItem />
            <OrganisationNavItem />
            <IndividualNavItem />
          </HasAccess>
          <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={null}>
            <BadgesNavItem />
          </HasAccess>
          <HasAccess
            roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER, USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]}
            Fallback={null}
          >
            <TeamsNavItem />
          </HasAccess>
          <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]} Fallback={null}>
            <MyTeamsNavItem />
          </HasAccess>
          <HasAccess
            roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN, USHG_HQ_APP_ROLE.USHG_ADMIN]}
            Fallback={null}
          >
            <RequestsNavItem />
          </HasAccess>
          <ReportsNavItem />
          {/* <CalendarNavItem /> */}
          <HasAccess
            roles={[
              USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
              USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
              USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
              USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
            ]}
            Fallback={null}
          >
            <MessagesNavItem />
          </HasAccess>

          <HasAccess
            roles={[
              USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
              USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
              USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
              USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
              USHG_HQ_APP_ROLE.INDIVIDUAL_USER,
            ]}
            Fallback={null}
          >
            <MyBadgesNavItem />
          </HasAccess>
          {showChat &&
            (process.env.REACT_APP_ENVIRONMENT === 'stage' ||
              process.env.REACT_APP_ENVIRONMENT === 'prod') && <ChatWidget />}
        </>
      }
      bottom={
        <>
          <HasAccess
            roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN, USHG_HQ_APP_ROLE.INDIVIDUAL_USER]}
            Fallback={null}
          >
            <SubscriptionReminder />
          </HasAccess>
          <NotificationsNavItem eventsCount={4} />
          <SettingsNavItem />
          <SideBar.SideBarCollapse />
          <SideBar.SideBarProfile />
        </>
      }
      {...props}
    ></SideBar>
  );
}

export default AppSideBar;
