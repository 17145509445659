import { useReduxAuthState } from '../../../../../hooks';
import AllMembersTableMain from '../../common/AllMembersTableMain';

interface PageTableProps {
  className?: string;
  tableHeadClassName?: string;
}

const PageTable = (props: PageTableProps) => {
  const { className } = props;
  const auth = useReduxAuthState();

  if (!auth.isAuthenticated) {
    throw new Error('User is not authenticated to view all members in this enterprise');
  }

  const tenantId = auth.user['custom:tenant_id'];
  const role = auth.user.role;

  return <AllMembersTableMain tenantId={tenantId} className={className} role={role} />;
};

export default PageTable;
