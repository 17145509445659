import { useNavigate, useParams } from 'react-router-dom';
import { TENANT_TYPE } from '../../organisation/constants/Billing';
import { useStrapiOrganizationData } from '../../organisation/hooks/useStrapiOrganizationData';
import BillingHistoryMain from '../../shared/components/BillingHistory/BillingHistoryTableMain';
import { calcTimeString } from '../../shared/helpers';
import TopNavBar from '../components/TopNavBar';
import useGetUserStatsQuery from '../hooks/useGetUserStatsQuery';
import { SideBarLayoutPageContentWrapper } from '../../../components/Wrapper';
import useBillingHistoryInvoiceQuery from '../../shared/hooks/useBillingHistoryInvoiceQuery';
import IndividualEmptyState from './IndividualEmptyState';

function SingleIndividualPage() {
  const { id } = useParams() ?? undefined;
  const { name } = useParams();
  const tenantType = TENANT_TYPE.INDIVIDUAL;
  const tenantId = undefined;
  const userStats = useGetUserStatsQuery({ username: id as string });

  const { singleView } = useStrapiOrganizationData();
  const { courseCompletedCardLabel, averageRatingCardLabel, engagementCardLabel, title } =
    singleView.Statistics;

  const avgValue = !userStats.data?.avgRating
    ? '0'
    : Number.isInteger(userStats.data?.avgRating)
    ? userStats.data?.avgRating
    : Math.round(userStats.data?.avgRating).toFixed(1);
  const navigate = useNavigate();
  const request = useBillingHistoryInvoiceQuery({
    tenantId,
    tenantType: tenantType as string,
    username: id as string,
  });
  const errorResponseData = (request.error as any)?.response?.data;
  const notExistError =
    !request.data && request.isError && errorResponseData?.errorCode == 'VALIDATION_FAILED';
  if (notExistError) {
    return (
      <div className="flex w-full">
        <IndividualEmptyState
          message={
            <div className="flex flex-col items-center gap-y-8">
              <span>{"We couldn't find any results."}</span>
              <button
                className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
                onClick={() => navigate('/manage/individual')}
              >
                {'Back to Individual'}
              </button>
            </div>
          }
        />
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col items-center gap-5">
      <TopNavBar isSingleIndividual={true} userFullName={name} />
      <SideBarLayoutPageContentWrapper className="flex-col gap-5">
        <div className="text-lg font-medium text-white">{title}</div>
        <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
          <div className="rounded-lg bg-card-bg p-4">
            <div className="mb-2 text-sm font-medium text-zinc-300">{engagementCardLabel}</div>
            <div className="text-3xl font-bold text-zinc-50">
              {calcTimeString(Number(userStats.data?.engagementHrs) * 60)}
            </div>
          </div>
          <div className="rounded-lg bg-card-bg p-4">
            <div className="mb-2 text-sm font-medium text-zinc-300">{courseCompletedCardLabel}</div>
            <div className="text-3xl font-bold text-zinc-50">
              {userStats.data?.coursesCompleted ? userStats.data?.coursesCompleted : 0}
            </div>
          </div>
          <div className="rounded-lg bg-card-bg p-4">
            <div className="mb-2 text-sm font-medium text-zinc-300">{averageRatingCardLabel}</div>
            <div className="text-3xl font-bold text-zinc-50">
              {userStats.data?.avgRating ? avgValue : 0}
            </div>
          </div>
        </div>
        <BillingHistoryMain
          className="!rounded-lg"
          username={id}
          isOrganizationView={true}
          tenantType={TENANT_TYPE.INDIVIDUAL}
        />
      </SideBarLayoutPageContentWrapper>
    </div>
  );
}

export default SingleIndividualPage;
