import { useMutation } from '@tanstack/react-query';
import { logoutUser } from '../api';

const useLogoutMutation = () => {
  const mutation = useMutation({
    mutationFn: async () => {
      const response = await logoutUser();
      return response;
    },
  });
  return mutation;
};

export default useLogoutMutation;
