import { useStrapiCoursesData } from '../../../../../../hooks/useStrapiCourseData';
import { SpeakerItemType } from '../../../../types/SingleCourseTypes';
import ImageWithAcronymFallback from '../../../../../../../../components/ImageWithAcronymFallback';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type Props = Omit<SpeakerItemType, 'jobTitle' | 'bio'> & {
  className?: string;
};

const SpeakerItem = ({ profilePic, name, totalCourses, className }: Props) => {
  const { moduleSpeaker } = useStrapiCoursesData();
  return (
    <div
      className={twMerge(
        clsx('flex w-full flex-row items-center p-4 py-2 hover:bg-[rgba(64,64,73,0.5)]', className)
      )}
    >
      <div className="w-max rounded-lg">
        <ImageWithAcronymFallback
          acronymWord={name}
          className={`max-h-[85px] min-h-[85px] min-w-[85px] max-w-[85px] rounded-lg border-2 border-zinc-700
          object-cover object-center`}
          acronymLogoClassName={`min-h-[85px] min-w-[85px] rounded-lg border-2 border-zinc-700 text-3xl font-medium`}
          imageKey={profilePic}
          alt={name}
        />
      </div>
      <div className="ml-3 flex flex-col justify-center text-left">
        <h3 className="my-1 font-bold line-clamp-2">{name}</h3>
        <div className="my-1 text-sm text-zinc-500 line-clamp-2">
          {totalCourses > 0 && (
            <>
              {totalCourses}{' '}
              {totalCourses === 1
                ? moduleSpeaker.coursesLabel.slice(0, -1)
                : moduleSpeaker.coursesLabel}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpeakerItem;
