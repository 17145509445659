import { useEffect } from 'react';
declare global {
  interface Window {
    FrontChat: (action: string, config?: any) => void;
  }
}
const ChatWidget = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
    script.async = true;
    script.defer = true;

    // Initialize Front Chat after script loads
    script.onload = () => {
      window.FrontChat('init', { chatId: process.env.REACT_APP_CHAT_ID, useDefaultLauncher: true });
    };

    // Add script to document
    document.body.appendChild(script);

    // Cleanup
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default ChatWidget;
