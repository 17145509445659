import { useParams } from 'react-router';
import SingleCourseHeader from './components/SingleCourseHeader/SingleCourseHeader';
import { Loader } from '../../../../components/Loader/Loader';
import SingleCourseDisplay from './components/SingleCourseDisplay/SingleCourseDisplay';

import { ErrorDisplay } from '../../../../components/DataTables';
import { useAppDispatch } from '../../../../hooks';
import { useEffect } from 'react';
import { clearModule } from './slice/SingleCourse.slice';
import useGetCourseModulesQuery from '../../hooks/useGetCourseModulesQuery';
import useCourseDetailsQuery from '../../hooks/useCourseDetailsQuery';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';
import useCourseSpeakerQuery from '../../hooks/useCourseSpeakerQuery';
import useSimilarCoursesQuery from '../../hooks/useSimilarCoursesQuery';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { SideBarLayoutPageContentWrapper } from '../../../../components/Wrapper';
import useLearningJourneyMetaQuery from '../../../learning-journeys/hooks/use-learning-journey-meta-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { LEARNING_JOURNEY_ERROR_CODE } from '../../../learning-journeys/constants/learning-journey';
import { USHGAPIError } from '../../../../types';
import { CoursesEmptyState, CoursesErrorState } from '../../../course-management/components/common';
import { useStrapiHQAdminCoursesData } from '../../../course-management/hooks/useStrapiHQAdminCourseData';
const SingleCourseView = () => {
  const { id, journeyId } = useParams();
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  // Clear previous selected module states
  useEffect(() => {
    appDispatch(clearModule());
  }, []);
  const coursesQuery = useCourseDetailsQuery({ id });
  const modulesQuery = useGetCourseModulesQuery({ id });
  const courseSpeakersQuery = useCourseSpeakerQuery({ courseId: id });
  const similarCoursesQuery = useSimilarCoursesQuery({ courseId: id });
  const { emptyMessages, topNav } = useStrapiHQAdminCoursesData();
  const learningJourneyMetaQuery = useLearningJourneyMetaQuery({
    courseId: `${id}`,
    journeyId: `${journeyId}`,
    enabled: !!journeyId,
  });
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const isQueryLoading =
    coursesQuery.isLoading ||
    modulesQuery.isLoading ||
    similarCoursesQuery.isLoading ||
    (!!journeyId && learningJourneyMetaQuery.isLoading);

  const notExistError =
    coursesQuery.isError && !coursesQuery.data && coursesQuery?.errorCode == 'COURSE_NOT_FOUND';

  // Error State
  const errorState = coursesQuery.isError && !coursesQuery.data && !notExistError; // Exclude notExistError

  // Loading State
  if (isQueryLoading) {
    return <Loader />;
  }

  if (!!journeyId && learningJourneyMetaQuery.isError) {
    if (axios.isAxiosError(learningJourneyMetaQuery.error)) {
      const data: USHGAPIError | undefined = learningJourneyMetaQuery.error.response
        ?.data as unknown as USHGAPIError;
      if (
        data &&
        (data.errorCode === LEARNING_JOURNEY_ERROR_CODE.LEARNING_JOURNEY_NOT_FOUND ||
          data.errorCode === LEARNING_JOURNEY_ERROR_CODE.COURSE_NOT_PART_OF_LEARNING_JOURNEY)
      ) {
        navigate(`/courses/${id}`);
      } else {
        return (
          <ErrorDisplay
            refetch={learningJourneyMetaQuery.refetch}
            allowsRefetch={true}
            isRefetching={learningJourneyMetaQuery.isRefetching}
            message={strapiErrorMessage['COURSE_NOT_FOUND']}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            className="h-full"
          />
        );
      }
    }
  }
  if (notExistError) {
    return (
      <div className="flex w-full">
        <CoursesEmptyState
          message={
            <div className="flex flex-col items-center gap-y-8">
              <span>{emptyMessages.commonEmptyMessage}</span>
              <button
                className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
                onClick={() => navigate('/courses')}
              >
                {topNav.backToCourseButton}
              </button>
            </div>
          }
        />
      </div>
    );
  }
  if (errorState) {
    return (
      <div className="flex h-full items-center justify-center">
        <CoursesErrorState
          refetchArray={[coursesQuery.refetch]}
          isRefetching={coursesQuery.isRefetching}
          allowsRefetch={true}
          type={ERROR_TYPES.SOMETHING_WENT_WRONG}
          message={strapiErrorMessage['SYSTEM_ERROR']}
        />
      </div>
    );
  }
  // if modules query fails
  if (modulesQuery.isError) {
    return (
      <ErrorDisplay
        refetch={modulesQuery.refetch}
        allowsRefetch={true}
        isRefetching={modulesQuery.isRefetching}
        message={strapiErrorMessage['MODULES_ERROR']}
        type={ERROR_TYPES.SOMETHING_WENT_WRONG}
      />
    );
  }
  return (
    <div className="relative w-full">
      <div className="relative">
        <SideBarLayoutPageContentWrapper className="flex-col justify-start py-6 px-3 text-white sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
          {/* ViewHeader */}
          {id && (
            <SingleCourseHeader
              title={coursesQuery.data && coursesQuery.data.name}
              courseId={parseInt(id)}
              isAssigned={coursesQuery.data.isAssigned}
              isCompleted={coursesQuery.data.isCompleted}
              isStarted={coursesQuery.data.isStarted}
              refetchDetails={coursesQuery.refetch}
            />
          )}
          <main id="main-content">
            <SingleCourseDisplay
              courseData={coursesQuery.data}
              modulesData={modulesQuery.data}
              speakersQuery={courseSpeakersQuery}
              similarCoursesQuery={similarCoursesQuery}
            />
          </main>
        </SideBarLayoutPageContentWrapper>
      </div>
    </div>
  );
};

export default SingleCourseView;
