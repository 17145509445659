import { gql } from '@apollo/client';
import {
  AllMembersFragment,
  BadgeFragment,
  CourseFragment,
  DashboardFragment,
  ForgotPasswordFragment,
  FormFragment,
  LoginFragment,
  MessageFragment,
  IndividualSignUpFragment,
  NotificationFragment,
  NotificationPreferencesFragment,
  OnboardingFragment,
  OrganizationFragment,
  RegistrationFragment,
  ReportsFragment,
  RequestFragment,
  ResetPasswordFragment,
  SettingFragment,
  SharedFragment,
  SideNavFragment,
  SpeakerFragment,
  TeamsFragment,
  TopNavFragment,
  LearningJourneyFragment,
  FrontFragment,
} from './fragments';

const Query = gql`
  ${NotificationFragment}
  ${AllMembersFragment}
  ${OrganizationFragment}
  ${TeamsFragment}
  ${FormFragment}
  ${LoginFragment}
  ${OnboardingFragment}
  ${BadgeFragment}
  ${RequestFragment}
  ${SettingFragment}
  ${SideNavFragment}
  ${DashboardFragment}
  ${RegistrationFragment}
  ${TopNavFragment}
  ${SpeakerFragment}
  ${CourseFragment}
  ${NotificationPreferencesFragment}
  ${SharedFragment}
  ${ReportsFragment}
  ${ForgotPasswordFragment}
  ${ResetPasswordFragment}
  ${MessageFragment}
  ${IndividualSignUpFragment}
  ${LearningJourneyFragment}
  ${FrontFragment}
  query ($errorCode: String, $chartName: String, $userPersona: String, $locale: I18NLocaleCode!) {
    reportTooltipContents(
      filters: { and: [{ reportType: { eq: $chartName } }, { userPersona: { eq: $userPersona } }] }
      pagination: { pageSize: 150 }
      locale: $locale
    ) {
      data {
        attributes {
          toolTipDescription
          reportType
          userPersona
        }
      }
    }
    apiErrors(
      filters: { errorCode: { eq: $errorCode } }
      pagination: { pageSize: 100 }
      locale: $locale
    ) {
      data {
        attributes {
          errorCode
          errorType
          errorMessage
          errorActionContent
        }
      }
    }
    errormessage(locale: $locale) {
      data {
        attributes {
          validEmail
          emailRequired
          passwordMin
          passwordRequired
          codeRequired
          confirmPassword
          confirmPasswordRequired
          passwordLower
          passwordUpper
          passwordSpecialCharacter
          passwordNumber
          passwordSpace
          emailAlreadyExists
          companyAlreadyExists
          teamNameAlreadyExist
          accountAlreadyExists
          clickHereLabel
          currentPasswordRequired
          signOutError
          activeLearnersLimit
        }
      }
    }
    toastMessage(locale: $locale) {
      data {
        attributes {
          confirmationCodeResendToastMessage
          confirmationCodeResendSuccessToastMessage
          userAlreadyActivatedToastMessage
          userNotFoundToastMessage
          limitExceededToastMessage
        }
      }
    }
    report(locale: $locale) {
      ...ReportsFragment
    }

    notification(locale: $locale) {
      ...NotificationFragment
    }
    allmember(locale: $locale) {
      ...AllMembersFragment
    }
    course(locale: $locale) {
      ...CourseFragment
    }
    organization(locale: $locale) {
      ...OrganizationFragment
    }
    team(locale: $locale) {
      ...TeamFragment
    }
    form(locale: $locale) {
      ...FormFragment
    }
    login(locale: $locale) {
      ...LoginFragment
    }
    onBoarding(locale: $locale) {
      ...OnboardingFragment
    }
    badge(locale: $locale) {
      ...BadgeFragment
    }
    request(locale: $locale) {
      ...RequestFragment
    }
    setting(locale: $locale) {
      ...SettingFragment
    }
    sideNavigator(locale: $locale) {
      ...SideNavFragment
    }
    dashboard(locale: $locale) {
      ...DashboardFragment
    }
    registration(locale: $locale) {
      ...RegistrationFragment
    }
    nav(locale: $locale) {
      ...TopNavFragment
    }
    speaker(locale: $locale) {
      ...SpeakerFragment
    }
    notifPreference(locale: $locale) {
      ...NotificationPreferencesFragment
    }
    shared(locale: $locale) {
      ...SharedFragment
    }
    forgotpassword(locale: $locale) {
      ...ForgotPasswordFragment
    }
    resetpassword(locale: $locale) {
      ...ResetPasswordFragment
    }
    message(locale: $locale) {
      ...MessageFragment
    }
    individualsignup(locale: $locale) {
      ...IndividualSignUpFragment
    }
    learningJourney(locale: $locale) {
      ...LearningJourneyFragment
    }
    hqFront(locale: $locale) {
      ...HQFrontFragment
    }
  }
`;

export default Query;
