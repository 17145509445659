import { useEffect, useState } from 'react';
import Dialog from '../../../../../../../../components/Dialog';
import { VideoController } from '../../../../../../../../components/MediaControls';
import { BasicSpinner } from '../../../../../../../../components/Spinners';
import { useAppDispatch, useAppSelector } from '../../../../../../../../hooks';
import { RootState } from '../../../../../../../../store';
import { MODULE_KEYS } from '../../../../../../../course-management/constants';
import { generateCDNMediaAssetURLWithKey } from '../../../../../../../shared/helpers';
import useSubmitAnswersMutation from '../../../../../../hooks/useSubmitAnswersMutation';
import {
  setCurrentModuleDetails,
  setCurrentModuleIndex,
} from '../../../../slice/SingleCourse.slice';
import {
  SubmitAssessmentArgsType,
  SubmitAssessmentResponse,
  submitFeedbackFormType,
  submitFeedbackPayloadType,
} from '../../../../types/SingleCourseTypes';
import AssessmentComponent from '../../../AssessmentComponent/AssessmentComponent';
import AssessmentResultModal from '../../../AssessmentComponent/AssessmentResultModal';
import ModulesComponent from '../../../ModulesComponent/ModulesComponent';
import SpeakerComponent from '../../../SpeakerComponent/SpeakerComponent';
import illustrationSrc from '../../../../assets/SuccessIllustrator.png';
import { CourseModule } from '../../../ModulesComponent/types/moduleTypes';
import useUpdateModuleLastAccessedMutation from '../../../../../../hooks/useUpdateModuleLastAccessedMutation';
import { PDFViewer } from '../../../../../FileViewers';
import useCompletedModuleMutation from '../../../../../../hooks/useCompleteModuleMutation';
import { Button } from '../../../../../../../../components/Buttons';
import CourseCompletedModal from '../CourseCompletedModal/CourseCompletedModal';
import OverlayVideoComponent from '../OverlayVideoComponent/OverlayVideoComponent';
import { getCourseModulesQueryKey } from '../../constants/queryKeyConstants';
import useDownloadPreSignedQuery from '../../../../../../hooks/useDownloadPreSignedQuery';
import useCourseModuleSpeakersQuery from '../../../../../../hooks/useCourseModuleSpeakerQuery';
import { useParams } from 'react-router-dom';
import { FEEDBACK_TYPE } from '../../constants/userRatingConstants';
import useFeedbackSubmitMutation from '../../../../../../hooks/useFeedbackSubmitMutation';
import { useStrapiCoursesData } from '../../../../../../hooks/useStrapiCourseData';
import { USER_COURSE_STATUS } from '../../../../../../../dashboard/types';
import useMediaCheckPointMutation from '../../../../../../hooks/useMediaCheckPointMutation';
import { useQueryClient } from '@tanstack/react-query';
import CourseCompletionComponent from '../CourseCompletionComponent/CourseCompletionComponent';

type Props = {
  modulesQuery: any;
  courseId: string | undefined;
  courseCategory: string;
  isAlreadyCourseCompleted: boolean;
};
const SingleModuleDisplay = ({
  modulesQuery,
  courseId,
  courseCategory,
  isAlreadyCourseCompleted,
}: Props) => {
  const { id, journeyId } = useParams();
  const queryClient = useQueryClient();
  // Store
  const appDispatch = useAppDispatch();
  const singleCourseSelector = (state: RootState) => state.singleCourse;
  const { modulesCount, currentModuleIndex, currentModuleDetails } =
    useAppSelector(singleCourseSelector);

  const moduleSpeakerQuery = useCourseModuleSpeakersQuery(currentModuleDetails);
  const submitAnswersMutation = useSubmitAnswersMutation();
  const moduleLastAccessedMutation = useUpdateModuleLastAccessedMutation();
  const completeModuleMutation = useCompletedModuleMutation();
  const feedbackSubmitMutation = useFeedbackSubmitMutation();
  const mediaCheckPointUpdate = useMediaCheckPointMutation();

  // State
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [assessmentResult, setAssessmentResult] = useState<SubmitAssessmentResponse>({
    passed: false,
    score: 0,
    answerDetails: [],
    alreadyCompleted: false,
    courseCompleted: false,
  });
  const [currentThumbnail, setCurrentThumbnail] = useState<string | undefined>(undefined);
  const [currentMedia, setCurrentMedia] = useState<string | null>(null);
  // Next module Overlay state
  const [nextModuleOverlay, setNextModuleOverlay] = useState<boolean>(false);
  const [currentModuleRestart, setcurrentModuleRestart] = useState<boolean>(false);
  const [nextModuleLoader, setNextModuleLoader] = useState<boolean>(false);
  // completed Module Mutation
  const [isCourseCompleted, setIsCourseCompleted] = useState<boolean>(false);
  // Local State for course completed Modal Open Status
  const [courseCompleteModalOpen, setCourseCompleteModalOpen] = useState<boolean>(false);
  const [isLastModule, setIsLastModuleOpen] = useState<boolean>(false);

  const setCurrentModuleState = (index: number) => {
    setCurrentMedia(null);
    appDispatch(setCurrentModuleIndex(index));
    appDispatch(
      setCurrentModuleDetails({
        id: modulesQuery.data[index].id,
        name: modulesQuery.data[index].name,
        type: modulesQuery.data[index].type,
        description: modulesQuery.data[index].description,
        pictureUrl: modulesQuery.data[index].thumbnail,
        closedCaptionsUrls: modulesQuery.data[index].closedCaptionsUrls,
        mediaUrl: modulesQuery.data[index].media,
        status: modulesQuery.data[index].status,
        seekableDuration: modulesQuery.data[index].watchedDuration * 60,
        startFromDuration: modulesQuery.data[index].leftOffDuration * 60,
      })
    );
    moduleLastAccessedMutation.mutate(modulesQuery.data[index].id, {
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const mediaCheckPointUpdateHandler = (duration: number) => {
    if (courseId && currentModuleDetails.id) {
      queryClient.setQueryData(
        [
          {
            scope: 'courses',
            item: 'modules',
            id: courseId,
          },
        ],
        (modules: any) => {
          return modules.map((module: any) => {
            if (module.id === currentModuleDetails.id) {
              const updateDuration = duration / 60;
              module.leftOffDuration = updateDuration;
              module.watchedDuration =
                updateDuration > module.watchedDuration ? updateDuration : module.watchedDuration;
              return module;
            }
            return module;
          });
        }
      );
      mediaCheckPointUpdate.mutate({
        moduleId: currentModuleDetails.id,
        courseId: parseInt(courseId),
        checkPointDuration: duration / 60,
      });
    }
  };

  // Setting default selected module
  useEffect(() => {
    const index = 0;
    if (currentModuleIndex == null && modulesQuery.data[index]) {
      setCurrentModuleState(index);
    }
  }, [modulesQuery.data]);

  // Get pre signed media url whenever state changes
  const downloadPreSignedQuery = useDownloadPreSignedQuery({ key: currentModuleDetails.mediaUrl });
  useEffect(() => {
    if (downloadPreSignedQuery.data) {
      if (downloadPreSignedQuery.data.url !== currentMedia) {
        setCurrentMedia(downloadPreSignedQuery.data.url);
      }
    }
  }, [downloadPreSignedQuery.data, currentMedia]);

  // Get constructed thumbnail url whenever state changes
  useEffect(() => {
    setCurrentThumbnail(
      generateCDNMediaAssetURLWithKey({
        key: currentModuleDetails.pictureUrl ? currentModuleDetails.pictureUrl : '',
      })
    );
  }, [currentModuleDetails]);

  // Submit Answer Mutation
  const assessmentSubmitHandler = (payload: SubmitAssessmentArgsType) => {
    submitAnswersMutation.mutate(payload, {
      onSuccess: (response) => {
        const { alreadyCompleted, courseCompleted } = response.data;
        setIsCourseCompleted(!alreadyCompleted && courseCompleted);
        setAssessmentResult(response.data);
        setIsModalOpen(true);
        queryClient.invalidateQueries({
          queryKey: getCourseModulesQueryKey(`${courseId}`),
        });

        // If the submission of current assessment lead to completion of course
        if (courseCompleted && !alreadyCompleted) {
          // If the user has completed the course after this assessment
          // Then invalidate the course basic details query
          queryClient.invalidateQueries({
            queryKey: ['courses', 'single-course', courseId],
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  // assessment submit handler
  const assessmentNextModuleHandler = () => {
    setIsModalOpen(false);
    if (!preventNextModuleAccess(modulesQuery.data)) nextModuleHandler();
  };

  // next module handler
  const nextModuleHandler = (reset?: boolean) => {
    const isModuleLoading =
      completeModuleMutation.isLoading ||
      modulesQuery.isFetching ||
      submitAnswersMutation.isLoading;
    if (isModuleLoading) {
      setNextModuleLoader(true);
    } else {
      if (reset) {
        const nextIndex = 0;
        if (nextIndex < modulesCount) {
          if (modulesQuery.data[nextIndex] && modulesQuery.data[nextIndex].isAccessible)
            setCurrentModuleState(nextIndex);
          else setNextModuleLoader(true);
        }
        // if last module
        else {
          setIsLastModuleOpen(true);
        }
        return;
      }

      if (isCourseCompleted) {
        setCourseCompleteModalOpen(true);
        queryClient.invalidateQueries([
          {
            scope: 'courses',
            item: 'single-course',
            queryKey: id,
          },
        ]);
      }
      // From one module to another module
      if (currentModuleIndex != null) {
        const nextIndex = currentModuleIndex + 1;
        if (nextIndex < modulesCount) {
          if (modulesQuery.data[nextIndex] && modulesQuery.data[nextIndex].isAccessible)
            setCurrentModuleState(nextIndex);
          else setNextModuleLoader(true);
        }
        // if last module
        else {
          setIsLastModuleOpen(true);
        }
      }
    }
  };

  // Update index when another module is selected
  const moduleClickHandler = (item: CourseModule, index: number) => {
    if (
      (currentModuleIndex != index || isLastModule) &&
      modulesQuery.data &&
      modulesQuery.data[index]
    ) {
      setCurrentModuleState(index);
      setIsLastModuleOpen(false);
    }
  };

  useEffect(() => {
    // NextModuleLoader -> true if the next module is inaccessible and waiting for all modules refetch
    if (
      !completeModuleMutation.isLoading &&
      !submitAnswersMutation.isLoading &&
      !modulesQuery.isFetching &&
      nextModuleLoader
    ) {
      setNextModuleLoader(false);
      nextModuleHandler();
    }
  }, [modulesQuery.isFetching, completeModuleMutation.isLoading, nextModuleLoader]);

  // Before Module End Handler
  const beforeEndHandler = () => {
    // Call Complete Module API only if th module is not completed
    if (currentModuleDetails.id && currentModuleDetails.status !== USER_COURSE_STATUS.COMPLETED) {
      completeModuleMutation.mutate(currentModuleDetails.id, {
        onSuccess: (response) => {
          queryClient.invalidateQueries({
            queryKey: getCourseModulesQueryKey(`${courseId}`),
          });
          const { alreadyCompleted, courseCompleted } = response.data;
          setIsCourseCompleted(!alreadyCompleted && courseCompleted);

          // If completion of current module lead to completion of course
          if (courseCompleted && !alreadyCompleted) {
            // If the user has completed the course after this video/audio/document
            // Then invalidate the course basic details query
            queryClient.invalidateQueries({
              queryKey: ['courses', 'single-course', courseId],
            });
          }
        },
      });
    }
  };

  // Audio, Video onEnd Handler
  const onEndHandler = () => {
    if (!preventNextModuleAccess(modulesQuery.data)) setNextModuleOverlay(true);
  };

  // Overlay Next module handler
  const overlayNextModuleHandler = () => {
    setNextModuleOverlay(false);
    nextModuleHandler();
  };

  const documentNextModuleHandler = () => {
    beforeEndHandler();
    setNextModuleLoader(true);
  };

  const feedbackSubmitHandler = (data: submitFeedbackFormType) => {
    if (id) {
      const payload: submitFeedbackPayloadType = {
        ...data,
        itemId: parseInt(id),
        type: FEEDBACK_TYPE.COURSE,
      };
      feedbackSubmitMutation.mutate(payload, {
        onSuccess: () => {
          setCourseCompleteModalOpen(false);
        },
      });
    }
  };

  const preventNextModuleAccess = (modules: CourseModule[]): boolean => {
    if (currentModuleIndex && modules.length === currentModuleIndex + 1) {
      const isModuleIncomplete = modules.findIndex(
        (module, index) =>
          module.status !== USER_COURSE_STATUS.COMPLETED && currentModuleIndex !== index
      );
      return isModuleIncomplete !== -1;
    } else {
      if (currentModuleIndex) return !modules[currentModuleIndex + 1].isAccessible;
    }
    return false;
  };

  const { singleModuleCommon, moduleSpeaker } = useStrapiCoursesData();

  const CourseModuleResetHandler = () => {
    nextModuleHandler(true);
    setIsLastModuleOpen(false);
    setIsCourseCompleted(false);
  };

  const overlayRestartHandler = () => {
    setcurrentModuleRestart(true);
    setTimeout(() => {
      setcurrentModuleRestart(false);
    }, 1000);
  };
  return (
    <div>
      {/* Video Section */}
      <div className="mb-4 w-full rounded sm:mb-8">
        {nextModuleLoader && (
          <div className="flex aspect-video w-full flex-col items-center justify-center gap-2 rounded-lg border border-zinc-700 bg-zinc-900 object-contain sm:aspect-[3/1.2]">
            <BasicSpinner />
          </div>
        )}

        {isLastModule &&
          (isCourseCompleted || isAlreadyCourseCompleted) &&
          !preventNextModuleAccess(modulesQuery.data) &&
          courseId && (
            <CourseCompletionComponent
              courseCategory={courseCategory}
              courseId={courseId}
              journeyId={journeyId}
              resetHandler={CourseModuleResetHandler}
            />
          )}

        {!isLastModule &&
          !nextModuleLoader &&
          currentModuleDetails.type === MODULE_KEYS.ASSESSMENT && (
            <AssessmentComponent
              submitAssessment={assessmentSubmitHandler}
              isSubmitting={submitAnswersMutation.isLoading}
            />
          )}

        {!isLastModule &&
          !nextModuleLoader &&
          (currentModuleDetails.type === MODULE_KEYS.VIDEO ||
            currentModuleDetails.type === MODULE_KEYS.AUDIO) &&
          (downloadPreSignedQuery.isLoading || !currentMedia ? (
            <div className="flex aspect-video w-full flex-col items-center justify-center border border-zinc-700 bg-zinc-900 object-contain sm:aspect-[3/1.2]">
              <BasicSpinner />
            </div>
          ) : (
            <div className="relative h-full w-full">
              {nextModuleOverlay && (
                <OverlayVideoComponent
                  onNextModuleClick={overlayNextModuleHandler}
                  onOutsideClick={() => setNextModuleOverlay(false)}
                  onComplete={overlayNextModuleHandler}
                  onRestart={overlayRestartHandler}
                />
              )}
              <VideoController
                className=" aspect-video w-full bg-black object-contain sm:aspect-[3/1.2]"
                thumbnail={currentThumbnail}
                video={currentMedia}
                closedcaptions={currentModuleDetails.closedCaptionsUrls}
                key={currentMedia}
                beforeEndHandler={beforeEndHandler}
                onEndHandler={onEndHandler}
                isSeekForwardEnabled={currentModuleDetails.status === USER_COURSE_STATUS.COMPLETED}
                mediaCheckPointUpdate={mediaCheckPointUpdateHandler}
                mediaCheckPoint={currentModuleDetails.startFromDuration}
                seekableDuration={currentModuleDetails.seekableDuration}
                restartStatus={currentModuleRestart}
                overlayStatus={nextModuleOverlay}
              />
            </div>
          ))}
        {!isLastModule &&
          !nextModuleLoader &&
          currentModuleDetails.type === MODULE_KEYS.DOCUMENT && (
            <>
              {downloadPreSignedQuery.isLoading ? (
                <div className="flex aspect-video h-full w-full flex-col items-center justify-center border border-zinc-700 bg-zinc-900 object-contain">
                  <BasicSpinner />
                </div>
              ) : (
                <div className="flex h-full w-full flex-col">
                  <PDFViewer
                    src={currentMedia}
                    name={currentModuleDetails.name}
                    className={'aspect-video'}
                  />
                  <div className="flex w-[100%] flex-row-reverse rounded-b-lg border-t border-zinc-700 bg-component-bg-dark py-2 px-4 text-xs">
                    {!preventNextModuleAccess(modulesQuery.data) && (
                      <Button
                        className="flex flex-row bg-base-brand px-4 py-2"
                        disabled={completeModuleMutation.isLoading}
                        onClick={documentNextModuleHandler}
                      >
                        <span>{singleModuleCommon.nextButton}</span>
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        {/* Left Section- Description and Similar Courses */}
        <div className="flex  w-full flex-col md:flex-[2]">
          {/* All Modules List */}
          <ModulesComponent
            modulesData={modulesQuery.data}
            moduleClickHandler={moduleClickHandler}
          />
        </div>
        {/* Right Section - Speakers and badges */}
        {
          <div
            className={`mb-5 flex w-full flex-col md:max-w-[21.75rem] md:flex-1 ${
              currentModuleDetails.type === MODULE_KEYS.ASSESSMENT ? 'invisible' : ''
            }`}
          >
            {/* SPEAKERS SECTION */}
            <div className="mb-6">
              <SpeakerComponent speakersQuery={moduleSpeakerQuery} title={moduleSpeaker.title} />
            </div>
          </div>
        }
      </div>
      {isModalOpen && (
        <div className="h-screen w-screen" style={{ backgroundImage: `url(${illustrationSrc})` }}>
          <Dialog
            open={isModalOpen}
            onOpenChange={(open) => setIsModalOpen(open)}
            triggerClassName="flex grow xs:w-fit items-center gap-2 bg-base-brand text-xs font-medium text-white rounded justify-center"
            onInteractOutside={(e) => {
              // Donot close the Modal when we click outside the Dialog Content
              e.preventDefault();
            }}
            Content={
              <AssessmentResultModal
                setModalOpen={setIsModalOpen}
                assessmentResult={assessmentResult}
                nextModuleHandler={assessmentNextModuleHandler}
              />
            }
            backgroundImage={assessmentResult.passed ? `url(${illustrationSrc})` : null}
          ></Dialog>
        </div>
      )}
      {/* Course Completed Modal */}
      <Dialog
        open={courseCompleteModalOpen}
        onOpenChange={(open) => {
          setIsCourseCompleted(open);
          setCourseCompleteModalOpen(open);
        }}
        triggerClassName="flex grow xs:w-fit items-center gap-2 bg-base-brand text-xs font-medium text-white rounded justify-center"
        onInteractOutside={(e) => {
          // Donot close the Modal when we click outside the Dialog Content
          e.preventDefault();
        }}
        Content={
          <CourseCompletedModal
            submitFormHandler={feedbackSubmitHandler}
            isSubmitting={feedbackSubmitMutation.isLoading}
          />
        }
        backgroundImage={`url(${illustrationSrc})`}
      ></Dialog>
    </div>
  );
};

export default SingleModuleDisplay;
