import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useReduxAuthState } from '../../../hooks';
import { UsersStatsFilter } from '../../teams/types/interface';
import { listAllLearningJourneys } from '../api/learning-journey';
import { DEFAULT_PAGE_SIZE } from '../constants/learning-journey';
import { QUERY_KEYS } from '../helpers/tanstack-query';

type useFetchLearningJourneyQueryProps = {
  tab: string;
  username?: Partial<UsersStatsFilter>;
};
const calculatePageOffset = (pageParam: number) =>
  (pageParam - 1) * DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL;

const useListAllLearningJourneys = ({ tab, username }: useFetchLearningJourneyQueryProps) => {
  const auth = useReduxAuthState();

  if (!auth.isAuthenticated) {
    throw new Error('User should be authenticated');
  }

  const fetchLJs = () => {
    const pageOffset = calculatePageOffset(1);

    return listAllLearningJourneys({
      tab,
      pageOffset,
      pageSize: DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL,
      ...(username && { username }),
    });
  };
  const [totalCount, setTotalCount] = useState<number>();
  const { isLoading, error, data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      QUERY_KEYS.listAllLearningJourneys({
        username: auth.user.username,
        tab,
      }),
      fetchLJs,
      {
        getNextPageParam: (lastPage, allPages) => {
          const fetchedCount = allPages.length * DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL;
          const totalCount = lastPage.total;

          if (fetchedCount >= totalCount) {
            return undefined;
          }

          return allPages.length + 1;
        },
      }
    );

  useEffect(() => {
    if (data) {
      setTotalCount(data.pages[0]?.total);
    }
  }, [data]);

  return { isLoading, error, data, fetchNextPage, totalCount, isFetchingNextPage, hasNextPage };
};

export default useListAllLearningJourneys;
