import { ErrorBoundary } from 'react-error-boundary';
import cn from '../../../../../utils/cn';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';
import LearningJourneyCoursesList from './LearningJourneyCoursesList';

interface Props {
  className?: string;
  isAssigned: boolean;
}

const LearningJourneyDetailMain = (props: Props) => {
  const { className, isAssigned } = props;

  return (
    <main id="main-content" className={cn(className)}>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <LearningJourneyCoursesList isAssigned={isAssigned} />
      </ErrorBoundary>
    </main>
  );
};

export default LearningJourneyDetailMain;
