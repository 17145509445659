import { gql } from '@apollo/client';
export const FrontFragment = gql`
  fragment HQFrontFragment on HqFrontEntityResponse {
    data {
      attributes {
        Chat {
          showChat
        }
      }
    }
  }
`;
