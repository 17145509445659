import { FadeLoader } from 'react-spinners';
import useStrapiLearningJourneyData from '../hooks/use-strapi-learning-journey-data';
import LearningJourneyCard from './LearningJourneysListPage/LearningJourneysMain/LearningJourneysList/LearningJourneyCard';
import cn from '../../../utils/cn';
import { SideBarLayoutPageContentWrapper } from '../../../components/Wrapper';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { LearningJourney } from '../types/api';

type AssignedCoursesView = {
  className?: string;
  currentTab?: any;
  currentTabState?: string;
  tab?: any;
  navigateToAllLJ?: any;
};

const AssignedLJView = ({ className, tab }: AssignedCoursesView) => {
  const { NoLearningJourney } = useStrapiLearningJourneyData();
  const { SinglePersonLJView } = useStrapiLearningJourneyData();
  const { noLJAssignedLabel, noLJAssignedMessage } = SinglePersonLJView;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  return (
    <>
      <SideBarLayoutPageContentWrapper
        className={`h-full flex-col content-around gap-5 py-6 ${className}`}
      >
        {!tab.isLoading && tab.data && tab.data.pages.length !== 0 && (
          <ul
            className={cn(
              'grid grid-cols-[repeat(auto-fill,minmax(var(--card-width),1fr))] items-stretch gap-3.5 [--card-width:calc(var(--min-thumbnail-width)+2*var(--card-padding-x))] lg:gap-4',
              '[--min-thumbnail-width:180px] xxs:[--min-thumbnail-width:200px] md:[--min-thumbnail-width:240px] lg:[--min-thumbnail-width:256px] xl:[--min-thumbnail-width:316px]',
              '[--card-padding-y:1.375rem] sm:[--card-padding-y:1.5rem] md:[--card-padding-y:1.875rem]',
              '[--card-padding-x:1.375rem] sm:[--card-padding-x:1.5rem] md:[--card-padding-x:1.875rem]',
              '[--min-thumbnail-height:calc(var(--min-thumbnail-width)/var(--lj-card-thumbnail-aspect-ratio))]',
              '[--lj-card-thumbnail-aspect-ratio:800/300]',
              className
            )}
          >
            {tab.data.pages.map((page: any) =>
              page.data.map((learningJourney: LearningJourney, index: number) => {
                // console.log("pagegegeg",page)
                if (index + 1 === page.data.length)
                  return (
                    <li key={learningJourney.id} className="flex w-full items-stretch">
                      <LearningJourneyCard learningJourney={learningJourney} />
                    </li>
                  );
                return (
                  <li key={learningJourney.id} className="flex w-full items-stretch">
                    <LearningJourneyCard learningJourney={learningJourney} />
                  </li>
                );
              })
            )}
          </ul>
        )}
      </SideBarLayoutPageContentWrapper>
      {!tab.isLoading && tab.data && tab.data.pages[0].total === 0 && (
        <div className="h-1/2">
          <div className="flex h-full items-center justify-center text-zinc-200">
            <div className="flex flex-col">
              <p className="m-auto text-base font-semibold">{noLJAssignedLabel}</p>
              <p className="m-auto text-sm font-normal text-zinc-500">{noLJAssignedMessage}</p>
            </div>
          </div>
        </div>
      )}
      {tab.isLoading && (
        <div className="flex h-full items-center justify-center">
          {<FadeLoader color="#E4E4E7" width="3px" />}
        </div>
      )}
      {tab.isFetchingNextPage && (
        <div className="flex justify-center text-zinc-200">
          <p>{NoLearningJourney.loadingLJ}</p>
        </div>
      )}
      {tab.error && (
        <div className="flex h-full items-center justify-center text-zinc-200">
          <p>{strapiErrorMessage['SYSTEM_ERROR']}</p>
        </div>
      )}
    </>
  );
};

export default AssignedLJView;
