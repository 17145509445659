/* eslint-disable react/jsx-key */
import * as Tooltip from '@radix-ui/react-tooltip';
import {
  MediaController,
  MediaControlBar,
  MediaTimeRange,
  MediaTimeDisplay,
  MediaVolumeRange,
  MediaPlayButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaMuteButton,
  MediaPipButton,
  MediaFullscreenButton,
  MediaLoadingIndicator,
  MediaCaptionsButton,
  MediaPlaybackRateButton,
  MediaCaptionsMenuButton,
  MediaCaptionsMenu,
} from 'media-chrome/dist/react';
import { useEffect, useRef, useState } from 'react';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { MEDIA_CHECKPOINT_INTERVAL } from '../../features/courses/constants/course';
import { ClosedCaptionsType } from '../../features/course-management/types';
import { useQueries } from '@tanstack/react-query';
import { downloadPresignedUrl } from '../../api';

type VideoProps = {
  className?: string;
  thumbnail?: string | undefined;
  video?: string;
  closedcaptions?: ClosedCaptionsType[];
  mediaCheckPoint?: number | null;
  mediaCheckPointUpdate?: (duration: number) => void;
  seekableDuration?: number | null;
  onEndHandler?: () => void;
  beforeEndHandler?: () => void;
  isSeekForwardEnabled?: boolean;
  restartStatus?: boolean;
  overlayStatus?: boolean;
};

let notReplay = true;

const disabledSeekStyles = {
  '--media-icon-color': '#6b7280',
  cursor: 'not-allowed',
};

const VideoController = ({
  className,
  thumbnail,
  video,
  onEndHandler,
  closedcaptions,
  mediaCheckPoint,
  beforeEndHandler,
  isSeekForwardEnabled = true,
  mediaCheckPointUpdate,
  seekableDuration,
  restartStatus,
  overlayStatus,
}: VideoProps) => {
  const [beforeEndCalled, setBeforeEndCalled] = useState<boolean>(false);
  const [mediaPlayerHotKeys, setMediaPlayerHotKeys] = useState<string>('');
  const videoPlayerRef = useRef<HTMLVideoElement>(null);
  const [playingDuration, setPlayingDuration] = useState<number>(mediaCheckPoint ?? 0);
  const [watchedDuration, setWatchedDuration] = useState<number>(seekableDuration ?? 0);
  const [previousUpdate, setPreviousUpdate] = useState<number>(0);
  const videoPlayingHandler = (event: any) => {
    if (videoPlayerRef.current && !videoPlayerRef.current.seeking) {
      const { currentTime, duration } = event.target;
      setPlayingDuration(currentTime);
      if (playingDuration > watchedDuration) setWatchedDuration(playingDuration);
      if (Math.abs(currentTime - previousUpdate) >= MEDIA_CHECKPOINT_INTERVAL) {
        if (mediaCheckPointUpdate) mediaCheckPointUpdate(currentTime);
        setPreviousUpdate(currentTime);
      }
      if (duration - currentTime <= 20) {
        if (!beforeEndCalled && beforeEndHandler) {
          beforeEndHandler();
          setBeforeEndCalled(true);
        }
      }
    }
  };
  if (overlayStatus) {
    notReplay = false;
  }
  useEffect(() => {
    if (videoPlayerRef && videoPlayerRef.current && isSeekForwardEnabled)
      setPlayingDuration(videoPlayerRef.current.duration);
    if (mediaCheckPoint && videoPlayerRef.current && notReplay) {
      videoPlayerRef.current.currentTime = mediaCheckPoint;
    }
    if (videoPlayerRef.current) {
      const currentTime = Math.round(videoPlayerRef.current.currentTime);
      const duration = Math.round(videoPlayerRef.current.duration);

      if (restartStatus) {
        notReplay = false;
        if (currentTime === duration) {
          videoPlayerRef.current.currentTime = 0;
          if (mediaCheckPointUpdate) mediaCheckPointUpdate(0);
        }
        if (!videoPlayerRef.current.currentTime) {
          videoPlayerRef.current.play();
        }
      } else if (currentTime === duration) {
        if (mediaCheckPointUpdate) mediaCheckPointUpdate(0);
        notReplay = false;
      }
    }
  }, [videoPlayerRef, restartStatus]);

  useEffect(() => {
    if (!isSeekForwardEnabled) setMediaPlayerHotKeys('noarrowright');
  }, [isSeekForwardEnabled]);

  const videoEndedHandler = (_event: any) => {
    if (onEndHandler) {
      onEndHandler();
    }
  };

  const onSeek = () => {
    if (
      !isSeekForwardEnabled &&
      videoPlayerRef.current &&
      videoPlayerRef.current.currentTime > watchedDuration
    ) {
      const delta = videoPlayerRef.current.currentTime - watchedDuration;
      if (Math.abs(delta) > 0.01) videoPlayerRef.current.currentTime = watchedDuration;
    }
  };

  const captionsTracks = useDownloadPreSignedQueryHook(closedcaptions);

  return (
    <Tooltip.Provider>
      {thumbnail && !video && (
        <div
          className={`aspect-video h-auto w-full max-w-full overflow-hidden bg-black object-contain lg:mr-auto  lg:shrink lg:grow ${
            className ?? ''
          }`}
        >
          <img className="h-full w-full object-cover" alt="Course Thumbnail" src={thumbnail}></img>
        </div>
      )}
      {video && (
        <MediaController
          video
          className={`aspect-video h-auto w-full max-w-full overflow-hidden bg-black object-contain lg:mr-auto  lg:shrink lg:grow ${
            className ?? ''
          }`}
          role="region"
          aria-label="video player"
          hotkeys={mediaPlayerHotKeys}
          mediaCaptionsShowing=""
        >
          <video
            playsInline
            slot="media"
            src={video}
            onTimeUpdate={videoPlayingHandler}
            onEnded={videoEndedHandler}
            ref={videoPlayerRef}
            poster={thumbnail}
            preload="auto"
            onSeeking={onSeek}
            className="h-full w-full object-contain"
            crossOrigin={captionsTracks.length > 0 ? '' : undefined}
          >
            {/* Use the variable captionsTracks here */}

            {captionsTracks.map((track) => (
              <track {...track} />
            ))}
          </video>
          {captionsTracks.length > 0 && (
            <MediaCaptionsMenu anchor="auto" hidden></MediaCaptionsMenu>
          )}
          <MediaLoadingIndicator
            slot="centered-chrome"
            className="hidden lg:block"
            isLoading
          ></MediaLoadingIndicator>

          <div slot="centered-chrome" className="flex w-full items-center justify-center lg:hidden">
            <MediaSeekBackwardButton
              className="m-[5%] w-[10%] bg-transparent p-0"
              role="button"
              aria-label="seek back 30 seconds"
              // disabled={playingDuration === 0}
              // tabIndex={playingDuration === 0 ? -1 : 0}
            ></MediaSeekBackwardButton>
            <MediaPlayButton
              className="m-[5%] w-[15%] bg-transparent p-0"
              role="button"
              aria-label="play"
            ></MediaPlayButton>
            <MediaSeekForwardButton
              className="m-[5%] w-[10%] bg-transparent p-0"
              role="button"
              aria-label="seek forward 30 seconds"
              disabled={!isSeekForwardEnabled}
              // tabIndex={!isSeekForwardEnabled ? -1 : 0}
              style={!isSeekForwardEnabled && disabledSeekStyles}
            ></MediaSeekForwardButton>
          </div>

          {/* Layout control elements in single bar */}
          <MediaControlBar className="h-8 lg:hidden">
            <MediaTimeRange className="h-auto bg-transparent"></MediaTimeRange>
            <MediaTimeDisplay className="bg-transparent" showDuration></MediaTimeDisplay>
          </MediaControlBar>

          {/* Layout control elements in single bar */}
          <MediaControlBar className="h-9 lg:h-11">
            <TooltipWrapper content={'Play'}>
              <MediaPlayButton
                className="hidden bg-gray-900/60 p-1.5 lg:block lg:p-2.5"
                role="button"
                aria-label="play"
              ></MediaPlayButton>
            </TooltipWrapper>
            <TooltipWrapper content={'Mute/Unmute'}>
              <MediaMuteButton
                className="bg-gray-900/60 p-1.5 lg:p-2.5"
                role="button"
                aria-label="unmute"
                mediaVolumeLevel="medium"
              ></MediaMuteButton>
            </TooltipWrapper>

            <MediaVolumeRange
              className="h-auto bg-gray-900/60 p-1.5 lg:p-2.5"
              role="slider"
              aria-label="volume"
            ></MediaVolumeRange>
            <TooltipWrapper content={'Rewind 30s'}>
              <MediaSeekBackwardButton
                className="hidden bg-gray-900/60 p-1.5 lg:block lg:p-2.5"
                role="button"
                aria-label="seek back 30 seconds"
              ></MediaSeekBackwardButton>
            </TooltipWrapper>
            <TooltipWrapper content={'Forward 30s'} enabled={isSeekForwardEnabled}>
              <MediaSeekForwardButton
                className="hidden bg-gray-900/60 p-1.5 lg:block lg:p-2.5"
                role="button"
                disabled={!isSeekForwardEnabled}
                aria-label="seek forward 30 seconds"
                style={!isSeekForwardEnabled && disabledSeekStyles}
              ></MediaSeekForwardButton>
            </TooltipWrapper>
            <div className="grow bg-gray-900/60 lg:hidden"></div>
            <MediaTimeRange
              className="hidden h-auto bg-gray-900/60 p-1.5 lg:block lg:p-2.5"
              role="slider"
              aria-label="seek"
            ></MediaTimeRange>
            <MediaTimeDisplay
              className="hidden bg-gray-900/60 lg:block"
              showDuration
              role="slider"
              aria-label="playback time"
            ></MediaTimeDisplay>
            <TooltipWrapper content="Playback rate">
              <MediaPlaybackRateButton
                className="self-center bg-gray-900/60 p-1.5 lg:p-2.5"
                role="button"
                aria-label="playback rate"
              ></MediaPlaybackRateButton>
            </TooltipWrapper>
            {captionsTracks.length > 0 && (
              <>
                <TooltipWrapper content="Captions">
                  <MediaCaptionsButton hidden></MediaCaptionsButton>
                </TooltipWrapper>
                <TooltipWrapper content="CaptionsMenu">
                  <MediaCaptionsMenuButton></MediaCaptionsMenuButton>
                </TooltipWrapper>
              </>
            )}
            <TooltipWrapper content="Pip Mode">
              <MediaPipButton
                className="bg-gray-900/60 p-1.5 lg:p-2.5"
                role="button"
                aria-label="enter picture in picture mode"
              ></MediaPipButton>
            </TooltipWrapper>
            <TooltipWrapper content="Fullscreen">
              <MediaFullscreenButton
                className="bg-gray-900/60 p-1.5 lg:p-2.5"
                role="switch"
                aria-label="enter full screen mode"
              ></MediaFullscreenButton>
            </TooltipWrapper>
          </MediaControlBar>
        </MediaController>
      )}
    </Tooltip.Provider>
  );
};

// Custom hook to handle fetching pre-signed URLs
const useDownloadPreSignedQueryHook = (closedcaptions?: ClosedCaptionsType[]) => {
  const captionsTracks = useQueries({
    queries:
      closedcaptions?.map((caption: ClosedCaptionsType) => ({
        queryKey: [{ scope: 'course-modules', item: 'pre-signed', key: caption.url }],
        queryFn: () => downloadPresignedUrl({ downloadPresignedUrlFilter: { key: caption.url } }),
        enabled: !!caption.url,
      })) || [],
  });

  // Mapping the results to get the necessary data for each closed caption track
  const captionTracksWithUrls = captionsTracks.map((result, index) => ({
    key: index,
    label: closedcaptions![index].language,
    kind: 'captions',
    srcLang: closedcaptions![index].language,
    src: result.data?.url,
  }));
  return captionTracksWithUrls;
};

export default VideoController;
