import { Filter, filterType } from '../../../../types/Filter.Helper';
import FilteredByWidgets from '../FilteredByWidgets/FilteredByWidgets';
import FilterWidgets from '../FilterWidgets/FilterWidgets';
import { twMerge } from 'tailwind-merge';
import { useCallback, useState } from 'react';
import { useStrapiTeamData } from '../../../../../teams/hooks/useStrapiTeamData';

type CourseHeaderProps = {
  appliedFilters: filterType[];
  setAppliedFilters: any;
  className?: string;
  showFilteredByWidgets?: boolean;
};

const AssignedCoursesHeader = ({
  appliedFilters,
  setAppliedFilters,
  className,
  showFilteredByWidgets,
}: CourseHeaderProps) => {
  const filterApplyHandler = (
    filterOrFilters: filterType | filterType[] | { type: Filter; action: 'remove-all' }
  ) => {
    // Handle remove all action
    if (!Array.isArray(filterOrFilters) && 'action' in filterOrFilters) {
      const { type } = filterOrFilters;
      setAppliedFilters(appliedFilters.filter((f) => f.type !== type));
      return;
    }

    // Handle array of filters
    if (Array.isArray(filterOrFilters)) {
      const filterType = filterOrFilters[0]?.type;
      if (!filterType) return;

      // Remove existing filters of the same type
      const filteredFilters = appliedFilters.filter((f) => f.type !== filterType);

      // Add new filters
      setAppliedFilters([...filteredFilters, ...filterOrFilters]);
      return;
    }

    // Handle single filter (for sort and search)
    const filter = filterOrFilters;
    if (filter.type === Filter.SORT) {
      const filteredFilters = appliedFilters.filter((f) => f.type !== Filter.SORT);
      setAppliedFilters([...filteredFilters, filter]);
    }
    if (filter.type === Filter.SEARCH) {
      const filteredFilters = appliedFilters.filter((f) => f.type !== Filter.SEARCH);
      setAppliedFilters([...filteredFilters, filter]);
    }
  };
  const { singlePerson } = useStrapiTeamData();
  const { courseAssignedTitle } = singlePerson;
  const [search, setSearch] = useState<string>('');

  const filterRemoveHandler = (filter: filterType) => {
    setAppliedFilters(
      appliedFilters.filter((f) => !(f.value === filter.value && filter.type === f.type))
    );
  };

  const clearSearch = () => {
    setAppliedFilters(appliedFilters.filter((f) => !(f.type === Filter.SEARCH)));
  };

  const clearFiltersHandler = useCallback(() => {
    setSearch('');
    setAppliedFilters([]);
  }, [setSearch, setAppliedFilters]);

  return (
    <div className={twMerge(`flex flex-col gap-3.5 bg-card-bg py-1 ${className}`)}>
      <p className="font-sans text-base font-bold text-white">{courseAssignedTitle}</p>
      <FilterWidgets
        appliedFilters={appliedFilters}
        searchClear={clearSearch}
        filterApplyHandler={filterApplyHandler}
        search={search}
        setSearch={setSearch}
      />
      {showFilteredByWidgets && (
        <FilteredByWidgets
          filters={appliedFilters}
          filterRemoveHandler={filterRemoveHandler}
          clearFiltersHandler={clearFiltersHandler}
        />
      )}
    </div>
  );
};

export default AssignedCoursesHeader;
